import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue } from 'recoil';
import classes from './ModalLikers.module.scss';
import { likersRecoil } from '../../recoil';
import Author from '../../../../components/author/Author';

const ModalLikers = ({ isOpen, setIsOpen }) => {
  const likers = useRecoilValue(likersRecoil);

  return (
    <>
      <div
        className={`${classes.modalLikers} ${isOpen ? classes['modalLikers--active'] : undefined}`}
        onClick={() => setIsOpen(false)}
      />
      <div
        className={`${classes.modalLikers__content} ${isOpen ? classes['modalLikers__content--active'] : undefined}`}
      >
        <div className={classes['modalLikers__content-close']}>
          <p>UpVotes</p>
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            icon={faClose}
            fontSize={20}
            onClick={() => setIsOpen(false)}
          />
        </div>
        <div style={{ paddingTop: 20 }}>
          {likers &&
            likers.map((item, idx) => (
              <div
                // href={`https://data.gokag.id.vn/${item.slug}`}
                // target="_blank"
                className={classes['modalLikers__content-item']}
                key={+idx}
                // rel="noreferrer"
              >
                <Author size="36" rank={Math.floor(((Math.random() * 10) % 4) + 1)} img={item.avatar} />
                <p>{`${item.last_name} ${item.first_name}`}</p>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ModalLikers;
