import React from 'react';
import style from './Animate.module.scss';

const Animate = () => (
  <div className={style.theAnimate}>
    <div className={style.theAnimate__wrapper}>
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '46%', left: '52%', width: '8px', height: '3px', animationDelay: '1.2s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '32%', left: '62%', width: '7px', height: '4px', animationDelay: '2.2s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '46%', left: '52%', width: '8px', height: '3px', animationDelay: '1.2s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '25%', left: '49%', width: '7px', height: '4px', animationDelay: '1.4s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '16%', left: '75%', width: '6px', height: '4px', animationDelay: '0s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '37%', left: '2%', width: '6px', height: '4px', animationDelay: '2.8s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '32%', left: '91%', width: '8px', height: '4px', animationDelay: '1.9s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '34%', left: '55%', width: '6px', height: '3px', animationDelay: '0.2s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '35%', left: '20%', width: '7px', height: '4px', animationDelay: '2.5s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '31%', left: '32%', width: '6px', height: '3px', animationDelay: '1.2s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '15%', left: '5%', width: '7px', height: '3px', animationDelay: '1.4s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '24%', left: '12%', width: '6px', height: '3px', animationDelay: '0.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '27%', left: '2%', width: '8px', height: '4px', animationDelay: '1.3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '31%', left: '19%', width: '8px', height: '4px', animationDelay: '2.4s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '11%', left: '4%', width: '6px', height: '4px', animationDelay: '0.8s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '48%', left: '28%', width: '8px', height: '4px', animationDelay: '0s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '35%', left: '90%', width: '8px', height: '4px', animationDelay: '3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '23%', left: '71%', width: '7px', height: '3px', animationDelay: '1.5s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '21%', left: '99%', width: '7px', height: '4px', animationDelay: '2.4s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '11%', left: '35%', width: '7px', height: '3px', animationDelay: '1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '17%', left: '57%', width: '8px', height: '3px', animationDelay: '2.5s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '19%', left: '100%', width: '7px', height: '3px', animationDelay: '0.7s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '40%', left: '66%', width: '8px', height: '4px', animationDelay: '2.5s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '38%', left: '23%', width: '8px', height: '3px', animationDelay: '1.2s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '49%', left: '35%', width: '6px', height: '3px', animationDelay: '0.1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '32%', left: '58%', width: '6px', height: '3px', animationDelay: '2.5s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '14%', left: '81%', width: '6px', height: '3px', animationDelay: '1.1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '37%', left: '37%', width: '8px', height: '4px', animationDelay: '0.5s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '36%', left: '53%', width: '6px', height: '3px', animationDelay: '0.1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '42%', left: '71%', width: '8px', height: '4px', animationDelay: '0.5s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '45%', left: '89%', width: '7px', height: '4px', animationDelay: '0.5s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '25%', left: '65%', width: '7px', height: '3px', animationDelay: '0s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '26%', left: '23%', width: '8px', height: '3px', animationDelay: '1.5s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '19%', left: '73%', width: '7px', height: '3px', animationDelay: '0.9s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '40%', left: '95%', width: '6px', height: '4px', animationDelay: '2.7s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '11%', left: '62%', width: '6px', height: '4px', animationDelay: '0.5s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '13%', left: '27%', width: '8px', height: '3px', animationDelay: '2.3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '32%', left: '5%', width: '8px', height: '4px', animationDelay: '3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '47%', left: '34%', width: '8px', height: '4px', animationDelay: '1.1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '48%', left: '79%', width: '8px', height: '3px', animationDelay: '1.3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '48%', left: '55%', width: '7px', height: '4px', animationDelay: '2.8s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '46%', left: '31%', width: '7px', height: '3px', animationDelay: '0.1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '19%', left: '99%', width: '8px', height: '4px', animationDelay: '1.9s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '20%', left: '50%', width: '8px', height: '4px', animationDelay: '2.9s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '30%', left: '59%', width: '8px', height: '4px', animationDelay: '1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '27%', left: '39%', width: '7px', height: '3px', animationDelay: '0.4s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '11%', left: '29%', width: '7px', height: '3px', animationDelay: '2.9s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '33%', left: '57%', width: '7px', height: '3px', animationDelay: '0.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '10%', left: '83%', width: '6px', height: '3px', animationDelay: '2.8s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '48%', left: '96%', width: '7px', height: '3px', animationDelay: '1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '12%', left: '61%', width: '7px', height: '4px', animationDelay: '2.8s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '45%', left: '1%', width: '8px', height: '3px', animationDelay: '0.9s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '35%', left: '67%', width: '8px', height: '4px', animationDelay: '0.1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '38%', left: '74%', width: '8px', height: '4px', animationDelay: '3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '36%', left: '62%', width: '6px', height: '4px', animationDelay: '1.2s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '21%', left: '98%', width: '7px', height: '4px', animationDelay: '0.3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '36%', left: '15%', width: '6px', height: '4px', animationDelay: '1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '12%', left: '98%', width: '7px', height: '4px', animationDelay: '2.2s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '48%', left: '27%', width: '6px', height: '3px', animationDelay: '3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '41%', left: '96%', width: '7px', height: '3px', animationDelay: '1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '42%', left: '85%', width: '7px', height: '3px', animationDelay: '0s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '18%', left: '66%', width: '7px', height: '4px', animationDelay: '0.4s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '16%', left: '30%', width: '6px', height: '3px', animationDelay: '0.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '18%', left: '9%', width: '6px', height: '4px', animationDelay: '0.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '13%', left: '65%', width: '6px', height: '3px', animationDelay: '2.1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '18%', left: '30%', width: '6px', height: '3px', animationDelay: '0.3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '13%', left: '60%', width: '6px', height: '3px', animationDelay: '0.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '17%', left: '61%', width: '8px', height: '4px', animationDelay: '2.5s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '40%', left: '67%', width: '7px', height: '4px', animationDelay: '0.4s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '38%', left: '79%', width: '6px', height: '4px', animationDelay: '1.5s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '17%', left: '100%', width: '7px', height: '4px', animationDelay: '0.1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '34%', left: '58%', width: '8px', height: '3px', animationDelay: '2.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '31%', left: '51%', width: '6px', height: '3px', animationDelay: '0.4s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '47%', left: '92%', width: '6px', height: '4px', animationDelay: '2.7s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '50%', left: '38%', width: '7px', height: '3px', animationDelay: '0s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '38%', left: '43%', width: '6px', height: '4px', animationDelay: '1.9s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '41%', left: '80%', width: '8px', height: '3px', animationDelay: '3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '11%', left: '76%', width: '8px', height: '4px', animationDelay: '2.8s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '18%', left: '70%', width: '6px', height: '4px', animationDelay: '1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '30%', left: '33%', width: '6px', height: '3px', animationDelay: '0.3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '28%', left: '1%', width: '6px', height: '3px', animationDelay: '1.9s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '20%', left: '66%', width: '6px', height: '4px', animationDelay: '1.5s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '45%', left: '1%', width: '8px', height: '3px', animationDelay: '2.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '49%', left: '55%', width: '7px', height: '3px', animationDelay: '2.1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '21%', left: '3%', width: '7px', height: '4px', animationDelay: '2.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '38%', left: '94%', width: '6px', height: '4px', animationDelay: '0.8s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '41%', left: '54%', width: '6px', height: '3px', animationDelay: '1.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '23%', left: '22%', width: '6px', height: '3px', animationDelay: '0.4s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '30%', left: '100%', width: '6px', height: '3px', animationDelay: '0.3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '26%', left: '12%', width: '6px', height: '4px', animationDelay: '0.9s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '45%', left: '93%', width: '6px', height: '3px', animationDelay: '2s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '21%', left: '32%', width: '8px', height: '3px', animationDelay: '1.2s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '47%', left: '41%', width: '8px', height: '3px', animationDelay: '2s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '35%', left: '77%', width: '7px', height: '4px', animationDelay: '1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '47%', left: '85%', width: '7px', height: '3px', animationDelay: '0.1s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '39%', left: '19%', width: '7px', height: '4px', animationDelay: '2.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '32%', left: '1%', width: '7px', height: '3px', animationDelay: '0.3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '37%', left: '57%', width: '7px', height: '3px', animationDelay: '1.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '31%', left: '68%', width: '8px', height: '4px', animationDelay: '1.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c3}`}
        style={{ top: '11%', left: '21%', width: '6px', height: '3px', animationDelay: '2.4s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '14%', left: '78%', width: '7px', height: '3px', animationDelay: '2.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '11%', left: '6%', width: '6px', height: '3px', animationDelay: '1.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '32%', left: '70%', width: '6px', height: '3px', animationDelay: '0.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c1}`}
        style={{ top: '15%', left: '39%', width: '8px', height: '4px', animationDelay: '0.6s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c4}`}
        style={{ top: '22%', left: '55%', width: '7px', height: '3px', animationDelay: '2.3s' }}
      />
      <span
        className={`${style.theAnimate__particle} ${style.theAnimate__c2}`}
        style={{ top: '14%', left: '91%', width: '6px', height: '4px', animationDelay: '1.4s' }}
      />
    </div>
  </div>
);

export default Animate;
